<template>
  <div class="loading-box" v-if="loading">
    <a-spin size="large" tip="加载中..." />
  </div>
  <router-view v-else-if="info" />
  <div class="empty-box content" v-else>
    <a-empty :image="simpleImage" />
  </div>
</template>

<script>
import { Empty } from "ant-design-vue";

const base = "/asset/outbound/borrow";
const menu = [
  {
    title: "数据统计",
    path: `${base}/data`,
    icon: "table",
  },
  {
    title: "规则权限",
    path: `${base}/setting`,
    icon: "setting",
  },
];

export default {
  name: "UseStatistics",
  data() {
    return {
      loading: false,
      info: null,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    };
  },
  beforeRouteLeave(to, from, next) {
    // 跳转到不是模板子菜单下面的页面 清空子菜单
    if (!["BorrowTemplateData", "BorrowTemplateSetting"].includes(to.name)) {
      this.$store.commit("setSubMenus", null);
    }
    next();
  },
  created() {
    this.getTemp();
  },
  methods: {
    getTemp() {
      this.loading = true;
      let url = `/admin/apply-temp?filter[is_oa]=1&filter[oa_type]=7&filter[type]=43`;
      this.$axios(url)
        .then((res) => {
          const info = res.data?.[0];
          if (info) {
            this.dealInfo(info);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    dealInfo(info) {
      this.$store.commit("setSubMenus", menu);
      this.$store.commit("setTemp", info);
      this.info = info;
    },
  },
};
</script>

<style lang="less" scoped>
.loading-box,
.empty-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
